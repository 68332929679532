import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../..';
import { getCookie, setCookie } from '../../../utils/cookies';
// import { logOut, setCredentials } from '../authSlice';
import { logOut, setCredentials } from '../credentialSlice';

const baseQuery = fetchBaseQuery({
  timeout: 600000,
  baseUrl:
    process.env.REACT_APP_ENV === 'PRODUCTION' ||
    process.env.REACT_APP_ENV === 'STAGING'
      ? `${window.location.protocol}//${window.location.host}/api/`
      : `${process.env.REACT_APP_API_URL}api/`,
  // credentials: 'include',
  // mode: "cors",
  prepareHeaders: (headers, { getState }) => {
    const token = getCookie('app__token');
    if (process.env.NODE_ENV === 'development') {
      headers.set('x-mock-response-code', '200');
    }
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
  const modifiedArgs =
    typeof args !== 'string'
      ? {
          ...args,
        }
      : args;
  if (typeof args !== 'string' && modifiedArgs.body) {
    const keys = Object.keys(modifiedArgs.body);
    keys.forEach(key => {
      const element = modifiedArgs.body[key];
      if (element === '') {
        modifiedArgs.body[key] = null;
      }
    });
  }
  const formattedArgsParams = modifiedArgs?.params
    ? {
        ...modifiedArgs,
        params: Object.fromEntries(
          Object.entries(args?.params).filter(([key, value]) => value !== '')
        ),
      }
    : modifiedArgs;
  let result: any = await baseQuery(formattedArgsParams, api, extraOptions);
  if (
    result?.error?.status === 401 &&
    result?.error?.data?.code === 'IDENTITY/EXPIRED-TOKEN'
  ) {
    const refreshResult = await baseQuery(
      `/identity/authenticate/refresh-token/${getCookie('app__refresh_token')}`,
      api,
      extraOptions
    );
    if (refreshResult?.data) {
      const { user } = api.getState().auth;
      api.dispatch(
        setCredentials({
          ...refreshResult.data,
          user,
        })
      );
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(logOut());
      window.location.replace('/login');
    }
  }

  if (
    result?.error?.status === 401 &&
    result?.error?.data?.code === 'IDENTITY/INVALID-TOKEN'
  ) {
    api.dispatch(logOut());
    window.location.replace('/login');
  }

  if (
    result?.error?.status === 403 &&
    result?.error?.data?.code === 'IDENTITY/UNAUTHORIZED-TOKEN'
  ) {
    api.dispatch(logOut());
    window.location.replace('/login');
  }
  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: builder => ({}),
  tagTypes: [
    'EmployeeList',
    'OrganizationUnitList',
    'OrganizationStructureList',
    'EmployeeDelete',
    'payrollDetailStatus',
    'getListPayItem',
    'getListEmployeSetup',
    'ListPayGroup',
    'LegalEntityList',
    'LegalEntityDelete',
    'LegalEntityDetail',
    'getDetailPaySetup',
    'ListEmployeeSetupManage',
    'ValueRounding',
    'exchangeRatesList',
    'JobGradeList',
    'EmploymentTypeList',
    'exchangeRateDetail',
    'organizationUnitDetails',
    'CostCenterList',
    'CostCenterDelete',
    'EmploymentTypeDetail',
    'JobPositionList',
    'JobPositionDetail',
    'OrganizationStructureDetail',
    'PayItemList',
    'PayItemDetail',
    'legalEntityBankList',
    'legalEntityBankDetail',
    'employeeCostCenter',
    'employeeBank',
    'employeeWorkLocation',
    'employeeBankDetail',
    'employeeFamily',
    'employeeFamilyDetail',
    'employeeEmployment',
    'employeeEducationList',
    'employeeEducationDetail',
    'employeeFiles',
    'workLocationList',
    'employeeAddressList',
    'employeeAddressDetail',
    'employeePersonalList',
    'employeePersonalDetail',
    'employeeStatutoryList',
    'employeeStatutoryDetail',
    'AccumulatorList',
    'employeeEarningDetail',
    'AccumulatorDetail',
    'AccumulatorDelete',
    'legalEntityStatutoryList',
    'legalEntityStatutoryDetail',
    'prevEmploymentList',
    'prevEmploymentDetail',
    'employeeDeductionDetail',
    'payrollInfoDetail',
    'PayGroupDetail',
    'UserAccessList',
    'UserAccessDetail',
    'payrollInfoDetail',
    'workLocationDetail',
    'jobGradeDetail',
    'dynamicGroupList',
    'dynamicGroupDetail',
    'payItemStepSetup',
    'DetailSeverity',
    'formulaList',
    'formulaDetail',
    'workflowList',
    'workflowDetail',
    'regularPayment',
    'offCyclePayment',
    'PaymentDetail',
    'accessControlList',
    'accessControlDetail',
    'payrollRunSummary',
    'payrollRunList',
    'employeeListPayrollRun',
    'expenseListPayrollRun',
    'payrollWorkflowDetail',
    'employeeEarningList',
    'employeeEarningDetail',
    'employeeRehireDetail',
    'legalEntityTaxLocationList',
    'legalEntityTaxLocationDetail',
    'EmployeeTerminationDetail',
    'taxLocationList',
    'taxLocationDetail',
    'employeeProfiles',
    'jobLevelDetail',
    'JobLevelList',
    'bankList',
    'bankDetail',
    'employeeUpcomingData',
    'payrollHistory',
    'bankFileList',
    'newsList',
    'newsDetail',
    'listImportHistory',
    'DetailImportHeader',
    'listImportDataInDetail',
    'importStatusApproval',
    'employeeCareerTransitionList',
    'employeeCareerTransitionDetail',
    'employeeCareerTransitionTermination',
    'oneTimePaymentList',
    'employmentDetailCareerTransition',
    'workLocationDetailCareerTransition',
    'taxLocationDetailCareerTransition',
    'earningsDetailCareerTransition',
    'deductionsDetailCareerTransition',
    'severancePayPlanList',
    'severancePayPlanDetail',
    'severancePayPaymentList',
    'severancePayPaymentDetailStatus',
    'severancePayPaymentSummary',
    'timeItemList',
    'timeItemDetail',
    '1721a1Configuration',
    '1721a1History',
    'bpjsTkBaruConfiguration',
    'listEmployeePaymentsSeverancePay',
    'bpjsTkLanjutanConfiguration',
    'updateConfigureBPJSKesehatan',
    'updateConfigure1721I',
    'timeEventList',
    'timeEventDetail',
    'listHistoryBupot2126',
    'listShift',
    'detailShift',
    'attendanceProcessList',
    'attendanceStatusList',
    'attendanceStatusDetails',
    'listSchedule',
    'detailSchedule',
    'listShiftAssignment',
    'listTimeOff',
    'EmployeeDropdown',
    'PayslipTemplate',
    'ListTask',
    'detailTimeOff',
    'listWorkSchedule',
    'OverlapTaskEmployee',
    'listAttendanceMachine',
    'listDailyAttendance',
    'ListHoliday',
    'JobSiteList',
    'JobSiteDetail',
    'employeeTimeoffDetails',
    'DetailAttendanceMachine',
    'fileList',
    'listTimeOffRequest',
    'detailTimeOffRequest',
    'overtimeList',
    'attendancePolicyList',
    'attendancePolicyDetail',
    'overtimeItemList',
    'essPolicyList',
    'profileDetail',
    'EssAccessControlList',
    'EssAccessControlDetail',
    'assignedEmployeesTemp',
    'initialOvertimeEmployees',
    'timeOffItemList',
    'timeOffItemDetail',
    'employeeTimeOffHistory',
    'employeeTimeOffEntitlement',
    'dailySummaryAttendanceProcess',
    'summaryViewAttendanceProcess',
    'essTimeOffRequest',
    'essTimeOffRequestDetail',
    'essTaskList',
    'claimTypes',
    'claimTypeDetails',
    'claims',
    'claimDetails',
  ],
});

export const { endpoints, reducerPath, reducer, middleware } = apiSlice;
