import {
  Box,
  SxProps,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { useState } from 'react';
import { ESSContainer } from '../../components/template';
import { TabList, CumulativeBox } from './containers';
import { useGetESSCumulativeQuery } from '../../store/feature/service/essEmployee';
import Breadcrumb from '../../components/molecule/breadcrumb/Breadcrumb';

const ListPayslip = () => {
  const match = useMediaQuery('(max-width:850px)');
  const { palette } = useTheme();
  const style: SxProps = {
    '& .header': {
      mb: '2rem',
    },
    '& .content': {
      display: 'flex',
      gap: '64px',
      flexDirection: match ? 'column-reverse' : 'row',
    },
    '& .font-bold': {
      fontWeight: 700,
    },
    '& .font-normal': {
      fontWeight: 400,
    },
    '& .activity-description': {
      mt: '0.5rem',
      fontWeight: 400,
    },
    '& .payslip-container': {
      flex: 1,
    },
    ...(match
      ? {
          padding: '12px 20px',
        }
      : {}),
  };

  const [year, setYearChoosen] = useState<any>();
  const { data: cumulativeData }: any = useGetESSCumulativeQuery({ year });

  return (
    <ESSContainer sx={style}>
      <Breadcrumb className="breadcrumb" />
      <Box>
        <Box className="header" display="flex" flexDirection="column" gap="8px">
          <Typography
            fontSize="24px"
            className="font-bold"
            color={palette.gray[100]}
          >
            Payslip
          </Typography>
          <Typography
            className="activity-description"
            variant="body14"
            color={palette.gray[80]}
          >
            Access and download your past payslips.
          </Typography>
        </Box>
        <Box className="content">
          <TabList
            className="payslip-container"
            setYearChoosen={setYearChoosen}
          />
          <CumulativeBox />
        </Box>
      </Box>
    </ESSContainer>
  );
};

export default ListPayslip;
