import { CircularProgress, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { Briefcase02 } from '../../../../components/atom/icon';
import EmptyCard from '../../../../components/molecule/emptyCard/EmptyCard';
import ExperienceSection from './ExperienceSection';
import { useGetESSExperienceListQuery } from '../../../../store/feature/service/essEmployee';

const Experience = () => {
  const params = useParams<{ id: string }>();
  const { data, isLoading }: any = useGetESSExperienceListQuery({
    id: params?.id || '',
  });
  const headerKey = {
    effectiveDate: 'Effective Date',
    companyName: 'Company Name',
    position: 'Position',
    startDate: 'Start Date',
    endDate: 'End Date',
    reasonOfResignation: 'Reason of Resignation',
  };

  return (
    <>
      {isLoading && <CircularProgress />}
      {!isLoading && (
        <Grid container spacing={2}>
          <Grid item xs={6} container rowSpacing={3}>
            {data?.map((item: any, index: number) => (
              <ExperienceSection
                key={JSON.stringify(item)}
                index={index}
                headerKey={headerKey}
                groupId={item?.data?.old?.groupId || item?.data?.new?.groupId}
              />
            ))}
            {data?.length < 1 && (
              <EmptyCard
                icon={<Briefcase02 />}
                label="Experience Info"
                customLabel="Experience"
                onClickAddTop={() => {}}
                isESS
              />
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Experience;
