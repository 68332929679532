import { Grid, useMediaQuery } from '@mui/material';
import EmploymentSection from './EmploymentSection';
import WorkLocationSection from './WorkLocationSection';

const EmploymentInfo = () => {
  const match = useMediaQuery('(max-width:1000px)');
  return (
    <Grid container spacing={2} direction={match ? 'column' : 'row'}>
      <Grid item xs={6} rowSpacing={3} padding="0">
        <EmploymentSection />
      </Grid>
      <Grid item xs={6} rowSpacing={3}>
        <WorkLocationSection />
      </Grid>
    </Grid>
  );
};

export default EmploymentInfo;
