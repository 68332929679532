import { apiSlice } from '../api/apiSlice';
import {
  OvertimeDetailsCriteriaList,
  OvertimeDetailsEmployeeList,
  OvertimeDetailsType,
  OvertimeListType,
} from '../../../@types/OvertimeTypes';
import { QueryParams } from '../../../@types/QueryParams';

export const overtimeApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    overtimeList: builder.query<
      OvertimeListType,
      Omit<QueryParams, 'sort' | 'sortType'>
    >({
      query: params => {
        const { search, page, searchString, limit } = params;
        return {
          url: '/time-management/overtimes',
          params: { search, page, limit, ...searchString },
        };
      },
      providesTags: ['overtimeList'],
    }),
    createOvertime: builder.mutation<any, any>({
      query: payload => ({
        url: '/time-management/overtimes',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['overtimeList'],
    }),
    getOvertimeItemDetail: builder.query<any, any>({
      query: ({ id }) => ({
        url: `/time-management/overtime-items/${id}`,
      }),
    }),
    overtimeDetails: builder.query<OvertimeDetailsType, { id: string }>({
      query: ({ id }) => ({
        url: `/time-management/overtimes/${id}`,
      }),
    }),
    overtimeDetailsListCriteria: builder.query<
      OvertimeDetailsCriteriaList,
      Omit<QueryParams, 'sort'> & { sortBy: string; id: string }
    >({
      query: ({ id, search, sortBy, sortType, page, searchString, limit }) => ({
        url: `/time-management/overtimes/${id}/criterias`,
        params: { search, sortBy, sortType, page, limit, ...searchString },
      }),
    }),
    overtimeDetailsListEmployee: builder.query<
      OvertimeDetailsEmployeeList,
      Omit<QueryParams, 'sort'> & { sortBy: string; id: string }
    >({
      query: ({ id, search, sortBy, sortType, searchString, page, limit }) => ({
        url: `/time-management/overtimes/${id}/employees`,
        params: { search, sortBy, sortType, page, limit, ...searchString },
      }),
    }),
    assignedEmployeesTemporary: builder.query<any, any>({
      query: params => {
        const { search, page, limit, sortType, sort, searchString, sessionId } =
          params;
        return {
          url: '/time-management/overtimes/assign-employees',
          params: {
            search,
            page,
            limit,
            sortType,
            sort,
            sessionId,
            ...searchString,
          },
        };
      },
      providesTags: ['assignedEmployeesTemp'],
    }),
    assignOvertimeTemporary: builder.mutation<any, any>({
      query: ({ sessionId, payload }) => ({
        url: `/time-management/overtimes/assign-employees/${sessionId}/temp`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['assignedEmployeesTemp', 'initialOvertimeEmployees'],
    }),
    submitOvertimeAssignment: builder.mutation<any, any>({
      query: ({ sessionId, payload }) => ({
        url: `/time-management/overtimes/assign-employees/${sessionId}`,
        method: 'POST',
        body: payload,
      }),
    }),
    getChangedEmployeesAssignment: builder.query<any, any>({
      query: ({ sessionId, type, overtimeId }) => ({
        url: `/time-management/overtimes/assign-employees/${sessionId}/status`,
        params: { type, overtimeId },
      }),
    }),
    getInitialOvertimeEmployees: builder.query<any, any>({
      query: params => {
        const {
          search,
          page,
          limit,
          sortType,
          sort,
          searchString,
          type,
          sessionId,
        } = params;
        return {
          url: '/time-management/overtimes/employees',
          params: {
            search,
            page,
            limit,
            sortType,
            sort,
            type,
            sessionId,
            ...searchString,
          },
        };
      },
      providesTags: ['initialOvertimeEmployees'],
    }),
  }),
});

export const {
  useOvertimeListQuery,
  useCreateOvertimeMutation,
  useGetOvertimeItemDetailQuery,
  useOvertimeDetailsQuery,
  useOvertimeDetailsListCriteriaQuery,
  useOvertimeDetailsListEmployeeQuery,
  useAssignOvertimeTemporaryMutation,
  useAssignedEmployeesTemporaryQuery,
  useSubmitOvertimeAssignmentMutation,
  useGetChangedEmployeesAssignmentQuery,
  useGetInitialOvertimeEmployeesQuery,
} = overtimeApiSlice;
