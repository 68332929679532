import { Grid, Typography, useTheme } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../store';
import { personalData } from '../../../../constants/employee';
import { objectMapper } from '../../../../utils/objectMap';
import {
  calculateAgeSinceBirth,
  formatBirthToGen,
  formatDateTimeFromUnixUTC,
} from '../../../../utils/date';
import Tags from '../../../../components/atom/tags/Tags';
import Marker from '../../../../components/atom/icon/Marker';
import LabelText from '../../../../components/atom/labelText/LabelText';
import HeaderAction from '../../../../components/molecule/action/HeaderAction';
import EmptyCard from '../../../../components/molecule/emptyCard/EmptyCard';
import HistoricalTimeLine from '../../../../components/molecule/historicalTimeLine/HistoricalTimeLine';
import { useGetESSPersonalDataQuery } from '../../../../store/feature/service/essEmployee';
import EmployeeSectionLoading from '../../../../components/atom/loadingSkeleton/EmployeeSectionLoading';

const valueBirthDate = (data: any, getTimePassed: Function) => {
  const { palette } = useTheme();
  switch (data?.dataPresentationStatus) {
    case 'ACTIVE':
      return (
        <Typography variant="body14" color={palette.gray[100]}>
          {formatDateTimeFromUnixUTC(data?.data?.old?.birthDate)}{' '}
          <Tags
            label={`${calculateAgeSinceBirth(
              data?.data?.old?.birthDate
            )} • ${formatBirthToGen(data?.data?.old?.birthDate)}`}
            variant="gray"
          />
        </Typography>
      );
    case 'PREVIOUS':
      return (
        <Typography
          variant="body14"
          color={
            data?.data?.old?.birthDate === data?.data?.new?.birthDate
              ? palette.gray[100]
              : palette.red[70]
          }
        >
          {formatDateTimeFromUnixUTC(data?.data?.old?.birthDate)}{' '}
          <Tags
            label={`${calculateAgeSinceBirth(
              data?.data?.old?.birthDate
            )} • ${formatBirthToGen(data?.data?.old?.birthDate)}`}
            variant="gray"
          />
        </Typography>
      );
    case 'UPCOMING':
      return (
        <Typography variant="body14" color={palette.gray[100]}>
          {formatDateTimeFromUnixUTC(data?.data?.old?.birthDate)}{' '}
          {data?.data?.old?.birthDate && (
            <Tags
              label={`${calculateAgeSinceBirth(
                data?.data?.old?.birthDate
              )} • ${formatBirthToGen(data?.data?.old?.birthDate)}`}
              variant="gray"
            />
          )}
          {data?.data?.old?.birthDate !== data?.data?.new?.birthDate && (
            <Typography
              variant="body14"
              color={palette.green[60]}
              component="span"
            >
              → {formatDateTimeFromUnixUTC(data?.data?.new?.birthDate)}{' '}
              <Tags
                label={getTimePassed(data?.data?.new?.birthDate)}
                variant="success"
              />
            </Typography>
          )}
        </Typography>
      );
    default:
      return '-';
  }
};

const PersonalInfoSection = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const params = useParams();

  const selectedHeader = `employee/personal-info/${id}`;
  const { headerDate }: any = useAppSelector(state => state.utils);
  const { data, isLoading }: any = useGetESSPersonalDataQuery({
    id,
    effectiveDate: headerDate[selectedHeader] || '',
  });

  const currentData: { [key: string]: any } = {
    ...objectMapper(data?.data?.old || {}),
    isDisability: data?.data?.old?.isDisability ? 'Yes' : 'No',
    birthDate: valueBirthDate(data, calculateAgeSinceBirth),
    ...objectMapper(
      data?.data?.old?.customFields.reduce((obj: any, field: any) => {
        let item = {
          ...obj,
        };
        if (field.name && field.value) {
          item = {
            ...item,
            [field.name]:
              typeof field.value === 'number'
                ? formatDateTimeFromUnixUTC(field.value)
                : field.value,
          };
        }
        if (field.name && !field.value) {
          item = {
            ...item,
            [field.name]: '-',
          };
        }
        return item;
      }, {}) || {}
    ),
    effectiveDate: (
      <HistoricalTimeLine headersState={data} selectedTab={selectedHeader} />
    ),
  };

  const upcomingData: { [key: string]: any } = {
    ...objectMapper(data?.data?.new || {}),
    isDisability: data?.data?.new?.isDisability ? 'Yes' : 'No',
    ...objectMapper(
      data?.data?.new?.customFields.reduce((obj: any, field: any) => {
        let item = {
          ...obj,
        };
        if (field.name && field.value) {
          item = {
            ...item,
            [field.name]:
              typeof field.value === 'number'
                ? formatDateTimeFromUnixUTC(field.value)
                : field.value,
          };
        }
        if (field.name && !field.value) {
          item = {
            ...item,
            [field.name]: '-',
          };
        }
        return item;
      }, {}) || {}
    ),
  };

  const customHeader = {
    ...personalData,
    ...data?.data?.old?.customFields.reduce((obj: any, field: any) => {
      let item = {
        ...obj,
      };
      if (field.name) {
        item = {
          ...item,
          [field.name]: field.name,
        };
      }
      return item;
    }, {}),
    ...data?.data?.new?.customFields.reduce((obj: any, field: any) => {
      let item = {
        ...obj,
      };
      if (field.name) {
        item = {
          ...item,
          [field.name]: field.name,
        };
      }
      return item;
    }, {}),
  };

  return (
    <>
      {data === null && (
        <EmptyCard
          icon={<Marker />}
          label="Personal Info"
          onClickAddTop={() =>
            navigate(`/employee/detail/${params.id}/personal-info/create`)
          }
        />
      )}
      {!isLoading && (
        <Grid item container spacing={1}>
          <Grid item xs={12}>
            <HeaderAction
              sx={{ maxWidth: '420px' }}
              label="Personal Info"
              isButtonEnabled={false}
              handleClickMenuAction={() => {}}
              disableActionButton
              isCorrectionEnabled={false}
            />
          </Grid>
          {Object.keys(customHeader).map((key: any) => (
            <Grid item xs={12}>
              <LabelText
                headerKey={customHeader[key]}
                currentData={currentData?.[key]}
                upcommingData={upcomingData?.[key]}
                dataPresentationStatus={data?.dataPresentationStatus}
              />
            </Grid>
          ))}
        </Grid>
      )}
      {isLoading && (
        <EmployeeSectionLoading
          rows={20}
          actionButtonLoading={false}
          customLeftWidth="100px"
          customRightWidth="200px"
        />
      )}
    </>
  );
};

export default PersonalInfoSection;
