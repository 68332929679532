import {
  Box,
  SxProps,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ESSContainer } from '../../components/template';
import { EmployeeBoxContainer, LatestPayslipContainer } from './containers';
import { useGetESSProfileQuery } from '../../store/feature/service/essEmployee';
import PendingApproval from '../../components/organism/ess/pendingApproval/PendingApproval';
import { Button } from '../../components/atom';
import { ChevronRight } from '../../components/atom/icon';
import Toast from '../../components/atom/toast/Toast';
import { useAppDispatch, useAppSelector } from '../../store';
import { removeMessage } from '../../store/feature/essTimeOffSlice';

const LatestPayslip = () => {
  const match = useMediaQuery('(max-width:800px)');
  const dispatch = useAppDispatch();
  const { messageError, messageSuccess } = useAppSelector(
    state => state.essTimeOff
  );
  const { palette } = useTheme();
  const style: SxProps = {
    ...(match
      ? {
          alignItems: 'center',
        }
      : {}),
    '& .header': {
      mb: '2rem',
    },
    '& .content': {
      display: 'flex',
      gap: '64px',
      alignItems: 'flex-start',
      flexDirection: match ? 'column-reverse' : 'row',
    },
    '& .font-bold': {
      fontWeight: 700,
    },
    '& .font-normal': {
      fontWeight: 400,
    },
    '& .activity-description': {
      mt: '0.5rem',
      fontWeight: 400,
    },
    '& .payslip-container': {
      flex: 1,
    },
  };

  const { data } = useGetESSProfileQuery({});
  const navigate = useNavigate();
  const handleClose = () => {
    dispatch(removeMessage());
  };

  return (
    <ESSContainer sx={style}>
      <Toast
        message={messageSuccess}
        variant="success"
        open={messageSuccess !== ''}
        onClose={handleClose}
      />
      <Toast
        message={messageError}
        variant="error"
        open={messageError !== ''}
        onClose={handleClose}
      />
      <Box>
        <Box className="header">
          <Typography
            className="font-bold"
            variant="h5"
            color={palette.gray[100]}
          >
            Hi, {data?.fullName || ''}
          </Typography>
          <Typography
            className="activity-description"
            variant="body14"
            color={palette.gray[100]}
          >
            What's your activity today?
          </Typography>
        </Box>
        <Box className="content">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1.5rem',
              flex: 2,
            }}
          >
            <LatestPayslipContainer
              className="payslip-container"
              latestPayslipDate="Jun 2023"
            />
            <Button
              variant="outlined"
              sx={{
                height: '60px',
                width: '336px',
                padding: '0.75rem 1rem',
              }}
              onClick={() => navigate('/ess/employee/approved-time-off')}
            >
              <Typography
                sx={{
                  width: '224px',
                  textAlign: 'start',
                }}
                variant="body14"
                color={palette.gray[100]}
              >
                Approved Time Off
              </Typography>
              <ChevronRight sx={{ stroke: 'gray' }} />
            </Button>
            <PendingApproval />
          </Box>
          <EmployeeBoxContainer data={data} sx={{ flex: 1 }} />
        </Box>
      </Box>
    </ESSContainer>
  );
};

export default LatestPayslip;
