import { Grid, useTheme } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../store';
import { objectMapper } from '../../../../utils/objectMap';
import { workLocationHeaderKey } from '../../../../constants/employee';
import LabelText from '../../../../components/atom/labelText/LabelText';
import HeaderAction from '../../../../components/molecule/action/HeaderAction';
import HistoricalTimeLine from '../../../../components/molecule/historicalTimeLine/HistoricalTimeLine';
import { useGetESSDetailWorkLocationQuery } from '../../../../store/feature/service/essEmployee';
import EmptyCard from '../../../../components/molecule/emptyCard/EmptyCard';
import Marker from '../../../../components/atom/icon/Marker';
import EmployeeSectionLoading from '../../../../components/atom/loadingSkeleton/EmployeeSectionLoading';

const WorkLocationSection = () => {
  const { id } = useParams();
  const selectedHeader = `employee/workLocation/${id}`;
  const { headerDate } = useAppSelector(state => state.utils);
  const { data, isLoading } = useGetESSDetailWorkLocationQuery({
    employeeId: id,
    effectiveDate: headerDate[selectedHeader],
  });

  const currentData: { [key: string]: any } = {
    ...objectMapper(data?.data?.old || {}),
    mobileNumber: data?.data?.old?.countryPhoneCode?.label
      ? `${data?.data?.old?.countryPhoneCode?.label} ${data?.data?.old?.mobileNumber}`
      : '-',
    effectiveDate: (
      <HistoricalTimeLine headersState={data} selectedTab={selectedHeader} />
    ),
  };

  const upcomingData: { [key: string]: any } = {
    ...objectMapper(data?.data?.new || {}),
    mobileNumber: data?.data?.new?.countryPhoneCode?.label
      ? `${data?.data?.new?.countryPhoneCode?.label} ${data?.data?.new?.mobileNumber}`
      : '--',
  };
  const { palette } = useTheme();

  return (
    <>
      {data?.effectiveDate && !isLoading && (
        <Grid item container spacing={1}>
          <Grid item xs={12}>
            <HeaderAction
              label="Work Location"
              isButtonEnabled={false}
              isCorrectionEnabled={false}
            />
          </Grid>
          {Object.keys({ ...workLocationHeaderKey }).map((key: any) => (
            <Grid item xs={12}>
              <LabelText
                headerKey={workLocationHeaderKey[key]}
                currentData={currentData?.[key]}
                upcommingData={upcomingData?.[key]}
                dataPresentationStatus={data?.dataPresentationStatus}
              />
            </Grid>
          ))}
        </Grid>
      )}
      {!data?.effectiveDate && !isLoading && (
        <EmptyCard
          style={{
            '& svg path': {
              stroke: palette.gray[80],
              strokeWidth: '5px',
            },
          }}
          icon={<Marker />}
          label="Work Location Info"
          isESS
          onClickAddTop={() => {}}
        />
      )}
      {isLoading && (
        <EmployeeSectionLoading
          rows={10}
          actionButtonLoading={false}
          customLeftWidth="100px"
          customRightWidth="200px"
        />
      )}
    </>
  );
};

export default WorkLocationSection;
