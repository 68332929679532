import { Box, Grid } from '@mui/material';
import { useAppSelector } from '../../../../store';
import { objectMapper } from '../../../../utils/objectMap';
import { formatDateTimeFromUnixUTC } from '../../../../utils/date';
import LabelText from '../../../../components/atom/labelText/LabelText';
import HeaderAction from '../../../../components/molecule/action/HeaderAction';
import HistoricalTimeLine from '../../../../components/molecule/historicalTimeLine/HistoricalTimeLine';
import SubHeader from '../../../../components/molecule/subHeader/SubHeader';
import { useGetESSExperienceDetailQuery } from '../../../../store/feature/service/essEmployee';
import EmployeeSectionLoading from '../../../../components/atom/loadingSkeleton/EmployeeSectionLoading';

const ExperienceSection = ({ index, headerKey, groupId, onClickAdd }: any) => {
  const selectedHeader = `employee/experience/${groupId}`;
  const { headerDate } = useAppSelector(state => state.utils);
  const { data, isLoading }: any = useGetESSExperienceDetailQuery({
    dataId: groupId,
    effectiveDate: headerDate[selectedHeader],
  });

  const currentData: { [key: string]: any } = {
    ...objectMapper(data?.data?.old || {}),
    startDate: formatDateTimeFromUnixUTC(data?.data?.old?.startDate),
    endDate: formatDateTimeFromUnixUTC(data?.data?.old?.endDate),
    effectiveDate: (
      <HistoricalTimeLine headersState={data} selectedTab={selectedHeader} />
    ),
  };

  const upcomingData: { [key: string]: any } = {
    ...objectMapper(data?.data?.new || {}),
    startDate: formatDateTimeFromUnixUTC(data?.data?.new?.startDate),
    endDate: formatDateTimeFromUnixUTC(data?.data?.new?.endDate),
  };

  return (
    <>
      {isLoading && (
        <Grid item xs={12}>
          <EmployeeSectionLoading
            rows={10}
            actionButtonLoading={false}
            customLeftWidth="100px"
            customRightWidth="200px"
          />
        </Grid>
      )}
      {!isLoading && (
        <Grid item container spacing={1}>
          {index === 0 && (
            <Grid item xs={12}>
              <HeaderAction
                label="Experience Info"
                handleAction={onClickAdd}
                labelAdd="Experience"
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Box
              sx={{
                '& .list': {
                  display: 'flex',
                  padding: '8px 12px',
                },
                '& .sub-title': {
                  fontWeight: 700,
                },
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
              }}
            >
              <SubHeader
                label="Experience"
                idx={index}
                onClickMenuAction={() => {}}
                data={data}
                isCorrectionEnabled={false}
              />
            </Box>
          </Grid>
          {Object.keys(headerKey).map((key: any) => (
            <Grid item xs={12}>
              <LabelText
                headerKey={headerKey[key]}
                currentData={currentData?.[key]}
                upcommingData={upcomingData?.[key]}
                dataPresentationStatus={data?.dataPresentationStatus}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};

export default ExperienceSection;
