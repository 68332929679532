import { Box, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../store';
import { useGetDetailEmployeeStatutoryQuery } from '../../../../store/feature/service/employee/statutory';
import { objectMapper } from '../../../../utils/objectMap';
import LabelText from '../../../../components/atom/labelText/LabelText';
import HeaderAction from '../../../../components/molecule/action/HeaderAction';
import HistoricalTimeLine from '../../../../components/molecule/historicalTimeLine/HistoricalTimeLine';
import SubHeader from '../../../../components/molecule/subHeader/SubHeader';
import { statutoryHeaderKey } from '../../../../constants/employee';
import { formatDateTimeFromUnixUTC } from '../../../../utils/date';
import { useGetESSStatutoryDetailQuery } from '../../../../store/feature/service/essEmployee';
import EmployeeSectionLoading from '../../../../components/atom/loadingSkeleton/EmployeeSectionLoading';

const StatutorySection = ({
  index,
  handleClickAction,
  groupId,
  statutoryType,
}: any) => {
  const selectedHeader = `employee/family/${groupId}`;
  const { headerDate } = useAppSelector(state => state.utils);
  const { data, isLoading }: any = useGetESSStatutoryDetailQuery({
    dataId: groupId,
    type: statutoryType,
    effectiveDate: headerDate[selectedHeader] || '',
  });
  const headerKey: any =
    statutoryHeaderKey[data?.data?.old?.statutoryType?.value] ||
    statutoryHeaderKey[data?.data?.new?.statutoryType?.value] ||
    {};

  const currentData: { [key: string]: any } = {
    ...objectMapper(data?.data?.old || {}),
    statutoryType: data?.data?.old?.statutoryType?.label || '-',
    socialSecurityActivationDate: formatDateTimeFromUnixUTC(
      data?.data?.old?.socialSecurityActivationDate
    ),
    healthCareActivationDate: formatDateTimeFromUnixUTC(
      data?.data?.old?.healthCareActivationDate
    ),
    effectiveDate: (
      <HistoricalTimeLine headersState={data} selectedTab={selectedHeader} />
    ),
  };

  const upcomingData: { [key: string]: any } = {
    ...objectMapper(data?.data?.new || {}),
    statutoryType: data?.data?.new?.statutoryType?.label || '-',
    socialSecurityActivationDate: formatDateTimeFromUnixUTC(
      data?.data?.new?.socialSecurityActivationDate
    ),

    healthCareActivationDate: formatDateTimeFromUnixUTC(
      data?.data?.new?.healthCareActivationDate
    ),
  };

  return (
    <>
      {isLoading && (
        <Grid item xs={12}>
          <EmployeeSectionLoading
            rows={10}
            actionButtonLoading={false}
            customLeftWidth="100px"
            customRightWidth="200px"
          />
        </Grid>
      )}
      {!isLoading && (
        <Grid item container rowSpacing={1}>
          <Grid item xs={12}>
            <Box
              sx={{
                '& .list': {
                  display: 'flex',
                  padding: '8px 12px',
                },
                '& .sub-title': {
                  fontWeight: 700,
                },
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
              }}
            >
              {index === 0 && (
                <Grid item xs={12}>
                  <HeaderAction
                    label="Statutory Info"
                    labelAdd="Statutory"
                    handleAction={() => {}}
                  />
                </Grid>
              )}
              <SubHeader
                label="Statutory"
                idx={index}
                onClickMenuAction={handleClickAction}
                data={data}
                isCorrectionEnabled={false}
              />
            </Box>
          </Grid>
          {data &&
            Object?.keys(headerKey)?.map((key: any) => (
              <Grid item xs={12}>
                <LabelText
                  headerKey={headerKey[key]}
                  currentData={currentData?.[key]}
                  upcommingData={upcomingData?.[key]}
                  dataPresentationStatus={data?.dataPresentationStatus}
                />
              </Grid>
            ))}
        </Grid>
      )}
    </>
  );
};

export default StatutorySection;
