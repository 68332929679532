import { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, SxProps, useMediaQuery } from '@mui/material';
import { ESSDetailsContainer } from '../../components/template';
import { EmployeeNavigator } from './containers';
import {
  BookOpen02,
  Briefcase02,
  CreditCard02,
  CurrencyDollarCircle,
  File04,
  Hearts,
  User02,
  Users03,
} from '../../components/atom/icon';
import { useAppSelector } from '../../store';
import { TabPanel } from '../../components/molecule/tabsButton/VerticalTabsButton';
import {
  PersonalInfo,
  EmployementInfo,
  BankInfo,
  ExperienceInfo,
  EducationInfo,
  FamilyInfo,
  FileInfo,
  Statutory,
} from './components';
import { useGetESSProfileQuery } from '../../store/feature/service/essEmployee';
import { essEmployeeConstants } from '../../constants';

const ESSEmployeeProfile = () => {
  const lgBreakpoint = useMediaQuery('(max-width:1200px)');
  const mdBreakPoint = useMediaQuery('(max-width:760px)');
  const location = useLocation();
  const { employeeID } = essEmployeeConstants;
  const style: SxProps = {
    '& .content-container': {
      display: 'flex',
      gap: '64px',
      flexDirection: mdBreakPoint ? 'column' : 'row',
    },
    '& .navigator': {
      width: mdBreakPoint ? 'auto' : '220px',
    },
    '& .content': {
      flex: 1,
    },
    ...(lgBreakpoint
      ? {
          padding: '24px 20px',
        }
      : {}),
  };
  const { data } = useGetESSProfileQuery({});
  const tabs = [
    {
      icon: <User02 />,
      label: 'Personal',
      component: () => (<PersonalInfo />) as ReactNode,
    },
    {
      icon: <Users03 />,
      label: 'Employment',
      component: () => (<EmployementInfo />) as ReactNode,
    },
    {
      icon: <CreditCard02 />,
      label: 'Bank',
      component: () => (<BankInfo />) as ReactNode,
    },
    {
      icon: <CurrencyDollarCircle />,
      label: 'Statutory',
      component: () => (<Statutory />) as ReactNode,
    },
    {
      icon: <Briefcase02 />,
      label: 'Experience',
      component: () => (<ExperienceInfo />) as ReactNode,
    },
    {
      icon: <BookOpen02 />,
      label: 'Education',
      component: () => (<EducationInfo />) as ReactNode,
    },
    {
      icon: <Hearts />,
      label: 'Family',
      component: () => (<FamilyInfo />) as ReactNode,
    },
    {
      icon: <File04 />,
      label: 'Files',
      component: () => (<FileInfo />) as ReactNode,
    },
  ];

  const { selectedTab } = useAppSelector(state => state.utils);
  return (
    <ESSDetailsContainer
      sx={style}
      title="My Profile"
      subtitle="Discover your employee information"
    >
      <Box className="content-container">
        <EmployeeNavigator className="navigator" tabs={tabs} data={data} />
        <Box className="content">
          {tabs.map((e: any, index: number) => (
            <TabPanel
              key={e.label}
              value={selectedTab[location.pathname] || 0}
              index={index}
            >
              {e.component()}
            </TabPanel>
          ))}
        </Box>
      </Box>
    </ESSDetailsContainer>
  );
};

export default ESSEmployeeProfile;
