import { Box, Skeleton } from '@mui/material';

const SidebarLoading = () => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
    }}
  >
    {Array.from({ length: 10 })
      .map((_, i) => i + 1)
      .map(v => (
        <Skeleton
          key={v}
          variant="text"
          sx={{ fontSize: '2rem', width: '240px' }}
        />
      ))}
  </Box>
);

export default SidebarLoading;
