import { Grid, useMediaQuery } from '@mui/material';
import { useParams } from 'react-router-dom';
import AddressSection from './AddressSection';
import PersonalInfoSection from './PersonalInfoSection';
import EmptyCard from '../../../../components/molecule/emptyCard/EmptyCard';
import Marker from '../../../../components/atom/icon/Marker';
import { useGetESSPersonalAddressListQuery } from '../../../../store/feature/service/essEmployee';

const PersonalInfo = () => {
  const match = useMediaQuery('(max-width:1000px)');
  const params = useParams();

  const { data } = useGetESSPersonalAddressListQuery({
    id: params.id,
    effectiveDate: '',
  });

  return (
    <Grid container spacing={2} direction={match ? 'column' : 'row'}>
      <Grid item xs={6} rowSpacing={3}>
        <PersonalInfoSection />
      </Grid>
      <Grid item xs={6} rowSpacing={3}>
        {data &&
          data?.length > 0 &&
          data?.map((item: any, index: number) => (
            <AddressSection
              key={JSON.stringify(item)}
              index={index}
              onClickAdd={() => {}}
              groupId={item?.data?.old?.groupId || item?.data?.new?.groupId}
            />
          ))}
        {data?.length === 0 && (
          <EmptyCard
            icon={<Marker />}
            label="Address"
            onClickAddTop={() => {}}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default PersonalInfo;
