import { Box } from '@mui/material';
import LoadingSkeleton from './LoadingSkeleton';

type EmployeeSectionLoadingProps = {
  rows?: number;
  actionButtonLoading?: boolean;
  customLeftWidth?: string;
  customRightWidth?: string;
};

const EmployeeSectionLoading = ({
  rows = 5,
  actionButtonLoading = true,
  customLeftWidth,
  customRightWidth,
}: EmployeeSectionLoadingProps) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '1.5rem',
    }}
  >
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <LoadingSkeleton sx={{ width: '200px' }} />
      {actionButtonLoading && <LoadingSkeleton sx={{ width: '25px' }} />}
    </Box>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
      }}
    >
      {Array(rows)
        .fill(2)
        .map(d => (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <LoadingSkeleton sx={{ width: customLeftWidth || '150px' }} />
            <LoadingSkeleton sx={{ width: customRightWidth || '300px' }} />
          </Box>
        ))}
    </Box>
  </Box>
);

export default EmployeeSectionLoading;
