import { useState } from 'react';
import { Box, Typography, useTheme, useMediaQuery } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { ESSContainer } from '../../components/template';
import TableView from '../../components/organism/tableView/TableView';
import { useEssTimeOffListQuery } from '../../store/feature/service/essTimeOffApiSlice';
import { useAppSelector, useAppDispatch } from '../../store';
import { objectMapper } from '../../utils/objectMap';
import { newformatDateTimeFromUnixUTC } from '../../utils/date';
import Toast from '../../components/atom/toast/Toast';
import { removeMessage } from '../../store/feature/essTimeOffSlice';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import Breadcrumb from '../../components/molecule/breadcrumb/Breadcrumb';
import { tableListHeaderKey } from '../../constants/timeOffRequest';
import { parseNumber } from '../../utils/parseToCurrency';

const ListApprovedTimeOff = () => {
  const match = useMediaQuery('(max-width:600px)');
  useDocumentTitle('Approved Time Off | TricorUnify');
  const navigate = useNavigate();
  const { palette } = useTheme();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { pages, search, searchString } = useAppSelector(state => state.utils);
  const { messageSuccess } = useAppSelector(state => state.essTimeOff);
  const [sort, setSort] = useState({
    sortBy: '',
    sortType: '',
  });

  const { data: dataRow, isLoading } = useEssTimeOffListQuery({
    page: pages[location.pathname] || 1,
    limit: 10,
    sortBy: sort.sortBy,
    sortType: sort.sortType,
    search: search[location.pathname] || '',
    searchString: searchString[location.pathname],
  });

  const handleCLose = () => {
    dispatch(removeMessage());
  };

  return (
    <ESSContainer
      sx={{
        ...(match
          ? {
              padding: '24px 20px',
            }
          : {}),
      }}
    >
      <Toast
        message={messageSuccess}
        open={messageSuccess !== ''}
        variant="success"
        onClose={handleCLose}
      />
      <Breadcrumb className="breadcrumb" />
      <Typography variant="h4" color={palette.gray[100]} fontWeight="700">
        Approved Time Off
      </Typography>
      <TableView
        activeDescription
        headerKey={tableListHeaderKey}
        sortBy={sort.sortBy}
        sortType={sort.sortType}
        setSort={setSort}
        showSort
        showCreate={false}
        showEdit={false}
        showFilter={false}
        rowData={
          dataRow?.data.map((data: any) => ({
            ...objectMapper(data),
            totalDay: parseNumber(data.totalDay, 'EN', 1),
            date: data?.date?.map((datum: number) => (
              <Box>{newformatDateTimeFromUnixUTC(datum)}</Box>
            )),
          })) || []
        }
        pageSize={Math.ceil((dataRow?.total || 0) / 10)}
        totalData={dataRow?.total || 0}
        onDescriptionClick={(id: string) =>
          navigate(`/ess/employee/approved-time-off/detail/${id}`)
        }
        showPagination
        showAction={false}
        isLoading={isLoading}
      />
    </ESSContainer>
  );
};

export default ListApprovedTimeOff;
