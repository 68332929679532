import { Box, SxProps, Typography, useTheme, Skeleton } from '@mui/material';
import { useEffect, useState } from 'react';
import { Button } from '../../../components/atom';
import { mergeDeep } from '../../../utils/deepMerge';
import { Eye, EyeOff } from '../../../components/atom/icon';
import {
  useGetESSCumulativeQuery,
  useGetESSLatestPayslipQuery,
} from '../../../store/feature/service/essEmployee';
import { parseNumber } from '../../../utils/parseToCurrency';

type EmployeeBoxContainerProps = {
  sx?: SxProps;
  className?: string;
};

const EmployeeBoxContainer = ({ sx, className }: EmployeeBoxContainerProps) => {
  const { palette } = useTheme();
  const [isOpen, setOpen] = useState(false);
  const style: SxProps = {
    backgroundColor: palette.gray[10],
    borderRadius: '1rem',
    width: '357px',
    maxWidth: '100%',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    height: 'max-content',
  };
  const [yearCumulative, setYear] = useState<null | string>(null);
  const { data: latest, isLoading }: any = useGetESSLatestPayslipQuery({});
  const { data: datas }: any = useGetESSCumulativeQuery({
    year: yearCumulative || '',
  });

  useEffect(() => {
    latest && setYear(latest?.latestPayslip?.name.split(' ')?.[1]);
  }, [latest]);

  return (
    <Box sx={mergeDeep(style, sx)} className={className}>
      <Box
        className="employee-box-avatar"
        display="flex"
        justifyContent="space-between"
        width="100%"
      >
        <Box>
          {!isLoading && (
            <Typography fontWeight="700" fontSize="18px">
              {`Cumulative of ${yearCumulative}`}
            </Typography>
          )}
          {isLoading && (
            <Skeleton
              variant="text"
              sx={{ fontSize: '2rem', width: '240px' }}
            />
          )}
        </Box>
        <Box>
          <Button
            sx={{
              '& svg': {
                '& path': {
                  stroke: palette.blue[70],
                  strokeWidth: '2',
                },
              },
              bgcolor: 'transparent',
              ':hover': {
                bgcolor: 'transparent',
              },
            }}
            variant="link"
            onClick={() => setOpen(prev => !prev)}
          >
            {isOpen ? (
              <>
                <EyeOff />
                <span>Hide</span>
              </>
            ) : (
              <>
                <Eye />
                <span>Show</span>
              </>
            )}
          </Button>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
        <Box>
          <Typography fontWeight="400" fontSize="14px">
            Total Net Pay
          </Typography>
          <Typography fontWeight="600" fontSize="16px">
            {isOpen ? `IDR ${parseNumber(datas?.totalNetPay)}` : 'IDR XXXXXX'}
          </Typography>
        </Box>
        <Box>
          <Typography fontWeight="400" fontSize="14px">
            Total Earnings
          </Typography>
          <Typography fontWeight="600" fontSize="16px">
            {isOpen ? `IDR ${parseNumber(datas?.totalEarning)}` : 'IDR XXXXXX'}
          </Typography>
        </Box>
        <Box>
          <Typography fontWeight="400" fontSize="14px">
            Total Deductions
          </Typography>
          <Typography fontWeight="600" fontSize="16px">
            {isOpen
              ? `IDR ${parseNumber(datas?.totalDeduction)}`
              : 'IDR XXXXXX'}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default EmployeeBoxContainer;
