import { CircularProgress, Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { bankHeaderKey } from '../../../../constants/employee';
import { CreditCard02 } from '../../../../components/atom/icon';
import EmptyCard from '../../../../components/molecule/emptyCard/EmptyCard';
import BankSection from './BankSection';
import { useGetESSBankListQuery } from '../../../../store/feature/service/essEmployee';

const Bank = () => {
  const navigate = useNavigate();
  const params = useParams();

  const { data, isLoading } = useGetESSBankListQuery({ id: params.id || '' });

  return (
    <>
      {isLoading && <CircularProgress />}
      {!isLoading && (
        <Grid container spacing={2}>
          <Grid item xs={6} container rowSpacing={3}>
            {data?.map((item: any, index: number) => (
              <BankSection
                key={JSON.stringify(item)}
                index={index}
                headerKey={bankHeaderKey}
                groupId={item?.data?.old?.groupId || item?.data?.new?.groupId}
              />
            ))}
            {data && data?.length < 1 && (
              <EmptyCard
                icon={<CreditCard02 />}
                label="Bank Info"
                isESS
                onClickAddTop={() =>
                  navigate(`/employee/detail/${params.id}/bank/create`)
                }
              />
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Bank;
