import { Grid, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useAppSelector } from '../../../../store';
import { useGetESSEmploymentDataQuery } from '../../../../store/feature/service/essEmployee';
import { objectMapper } from '../../../../utils/objectMap';
import { Tags } from '../../../../components/atom';
import LabelText from '../../../../components/atom/labelText/LabelText';
import HeaderAction from '../../../../components/molecule/action/HeaderAction';
import HistoricalTimeLine from '../../../../components/molecule/historicalTimeLine/HistoricalTimeLine';
import { employmentHeaderKey } from '../../../../constants/employee';
import { formatDateTimeFromUnixUTC } from '../../../../utils/date';
import EmployeeSectionLoading from '../../../../components/atom/loadingSkeleton/EmployeeSectionLoading';

const valueJoindate = (data: any, getTimePassed: Function) => {
  const { palette } = useTheme();
  switch (data?.dataPresentationStatus) {
    case 'ACTIVE':
      return (
        <Typography variant="body14" color={palette.gray[100]}>
          {formatDateTimeFromUnixUTC(data?.data?.old?.joinDate)}{' '}
          <Tags
            label={getTimePassed(data?.data?.old?.joinDate)}
            variant="gray"
          />
        </Typography>
      );
    case 'PREVIOUS':
      return (
        <Typography
          variant="body14"
          color={
            data?.data?.old?.joinDate === data?.data?.new?.joinDate
              ? palette.gray[100]
              : palette.red[70]
          }
        >
          {formatDateTimeFromUnixUTC(data?.data?.old?.joinDate)}{' '}
          <Tags
            label={getTimePassed(data?.data?.old?.joinDate)}
            variant="gray"
          />
        </Typography>
      );
    case 'UPCOMING':
      return (
        <Typography variant="body14" color={palette.gray[100]}>
          {formatDateTimeFromUnixUTC(data?.data?.old?.joinDate)}{' '}
          <Tags
            label={getTimePassed(data?.data?.old?.joinDate)}
            variant="gray"
          />
          {data?.data?.old?.joinDate !== data?.data?.new?.joinDate && (
            <Typography
              variant="body14"
              color={palette.green[60]}
              component="span"
            >
              → {formatDateTimeFromUnixUTC(data?.data?.new?.joinDate)}{' '}
              <Tags
                label={getTimePassed(data?.data?.new?.joinDate)}
                variant="success"
              />
            </Typography>
          )}
        </Typography>
      );
    default:
      return '-';
  }
};
const EmploymentSection = () => {
  const { id } = useParams();
  const selectedHeader = `employee/employment/${id}`;
  const { headerDate } = useAppSelector(state => state.utils);
  const { data, isLoading }: any = useGetESSEmploymentDataQuery({
    id,
    effectiveDate: headerDate[selectedHeader],
  });
  const getTimePassed = React.useCallback(
    (timestamped: number) => {
      const currentTimestamp = Date.now() / 1000;
      const timestamp = timestamped;
      const date1 = dayjs.unix(timestamp);
      const date2 = dayjs.unix(currentTimestamp);
      const years = date2.diff(date1, 'year');
      const months = date2.diff(date1, 'month') % 12;
      const pluralYearsSuffix = years > 1 ? 's' : '';
      const pluralMonthsSuffix = months > 1 ? 's' : '';
      return `${years} year${pluralYearsSuffix} ${months} month${pluralMonthsSuffix}`;
    },
    [data]
  );

  const determineTagsVariant = (status: string) => {
    if (status === 'Active') {
      return 'primary';
    }
    if (status === 'Inactive') {
      return 'gray';
    }
    return 'warning';
  };

  const currentData: { [key: string]: any } = {
    ...objectMapper(data?.data?.old || {}),
    isPermanent: data?.data?.old?.isPermanent ? 'Yes' : 'No',
    status: (
      <Tags
        variant={determineTagsVariant(data?.data?.old?.status)}
        label={data?.data?.old?.status}
      />
    ),
    isProbation: data?.data?.old?.isProbation ? 'Yes' : 'No',
    probationReminder: data?.data?.old?.probationReminder
      ? formatDateTimeFromUnixUTC(data?.data?.old?.probationReminder)
      : '-',
    probationDuration: `${
      data?.data?.old?.probationDuration
        ? data?.data?.old?.probationDuration
        : '-'
    } ${
      data?.data?.old?.probationDurationType
        ? data?.data?.old?.probationDurationType?.label
        : ' '
    }`,
    endDateReminder: data?.data?.old?.endDateReminder
      ? formatDateTimeFromUnixUTC(data?.data?.old?.endDateReminder)
      : '-',
    joinDate: valueJoindate(data, getTimePassed),
    endDate: data?.data?.old?.endDate
      ? formatDateTimeFromUnixUTC(data?.data?.old?.endDate)
      : '-',
    ...objectMapper(
      data?.data?.old?.customFields.reduce((obj: any, field: any) => {
        let item = {
          ...obj,
        };
        if (field.name && field.value) {
          item = {
            ...item,
            [field.name]:
              typeof field.value === 'number'
                ? formatDateTimeFromUnixUTC(field.value)
                : field.value,
          };
        }
        if (field.name && !field.value) {
          item = {
            ...item,
            [field.name]: '-',
          };
        }
        return item;
      }, {}) || {}
    ),
    effectiveDate: (
      <HistoricalTimeLine headersState={data} selectedTab={selectedHeader} />
    ),
  };

  const upcomingData: { [key: string]: any } = {
    ...objectMapper(data?.data?.new || {}),
    // fix status capsule to disappearad on upcoming changes
    status: (
      <Tags
        variant={determineTagsVariant(data?.data?.new?.status)}
        label={data?.data?.new?.status}
      />
    ),
    isPermanent: data?.data?.new?.isPermanent ? 'Yes' : 'No',
    ...objectMapper(
      data?.data?.new?.customFields.reduce((obj: any, field: any) => {
        let item = {
          ...obj,
        };
        if (field.name && field.value) {
          item = {
            ...item,
            [field.name]:
              typeof field.value === 'number'
                ? formatDateTimeFromUnixUTC(field.value)
                : field.value,
          };
        }
        if (field.name && !field.value) {
          item = {
            ...item,
            [field.name]: '-',
          };
        }
        return item;
      }, {}) || {}
    ),
    isProbation: data?.data?.new?.isProbation ? 'Yes' : 'No',
    endDateReminder: data?.data?.old?.endDateReminder
      ? formatDateTimeFromUnixUTC(data?.data?.new?.endDateReminder)
      : '-',
    probationReminder: data?.data?.new?.probationReminder
      ? formatDateTimeFromUnixUTC(data?.data?.new?.probationReminder)
      : '-',
    probationDuration: `${
      data?.data?.new?.probationDuration
        ? data?.data?.new?.probationDuration
        : '-'
    } ${
      data?.data?.new?.probationDurationType
        ? data?.data?.new?.probationDurationType?.label
        : ' '
    }`,
    endDate: data?.data?.new?.endDate
      ? formatDateTimeFromUnixUTC(data?.data?.new?.endDate)
      : '-',
  };

  const customeHeaderKey = {
    ...employmentHeaderKey,
    ...data?.data?.old?.customFields.reduce((obj: any, field: any) => {
      let item = {
        ...obj,
      };
      if (field.name) {
        item = {
          ...item,
          [field.name]: field.name,
        };
      }
      return item;
    }, {}),
    ...data?.data?.new?.customFields.reduce((obj: any, field: any) => {
      let item = {
        ...obj,
      };
      if (field.name) {
        item = {
          ...item,
          [field.name]: field.name,
        };
      }
      return item;
    }, {}),
  };

  return (
    <>
      {!isLoading && (
        <Grid item container spacing={1}>
          <Grid item xs={12}>
            <HeaderAction
              label="Employment Info"
              isButtonEnabled={false}
              isCorrectionEnabled={false}
            />
          </Grid>
          {Object.keys(customeHeaderKey).map((key: any) => (
            <Grid item xs={12}>
              <LabelText
                sx={{ gap: '1rem' }}
                headerKey={customeHeaderKey[key]}
                currentData={currentData?.[key]}
                upcommingData={upcomingData?.[key]}
                dataPresentationStatus={data?.dataPresentationStatus}
              />
            </Grid>
          ))}
        </Grid>
      )}
      {isLoading && (
        <EmployeeSectionLoading
          rows={20}
          actionButtonLoading={false}
          customLeftWidth="100px"
          customRightWidth="200px"
        />
      )}
    </>
  );
};

export default EmploymentSection;
